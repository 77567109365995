// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "output" ]

  showSidebar() {
    $('.ui.sidebar')
      .sidebar('setting', 'transition', 'overlay')
      .sidebar('toggle');
  }

  readNotification() {
    const url = '/api/v1/notifications'

    $.ajax({
      url: url,
      method: 'DELETE'
    })
  }
}
