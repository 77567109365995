// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    fetch('/song_requests/history_bgm', { method: 'GET' })
  }
}
