import Cookies from "js-cookie"
import UAParser from "ua-parser-js"
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "modal" ]

  connect() {
    const parser = new UAParser()
    if (parser.getResult().device.type !== "mobile") return
    if (Cookies.get("_bgmnow_webapp_warn_mobile") === "true") return

    $(this.modalTarget).modal({
      closable: false,
      onApprove: () => {
        Cookies.set('_bgmnow_webapp_warn_mobile', 'true', { expires: 7 })
      }
    }).modal('show')
  }
}
