import * as Sentry from '@sentry/browser';
import { BrowserTracing } from "@sentry/tracing";

const getSampleRate = () => {
  if (process.env.NODE_ENV === 'development') {
    return 0;
  }
  switch (window.SENTRY_ENVIRONMENT) {
    case 'production': {
      return 1;
    }
    case 'development': {
      return 0.3;
    }
    default: {
      return 0;
    }
  }
};

Sentry.init({
  dsn: 'https://52e2cc18ceb2463381666fe7776f7d71@o1165589.ingest.sentry.io/6610307',
  environment: window.SENTRY_ENVIRONMENT,
  integrations: [new BrowserTracing()],
  sampleRate: getSampleRate(),
});

window.Sentry = Sentry;
