import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = [ "musicInstrument", "code", "musicKey", "bgm", "rhythm", "patern", "pan", "serialNumber", "file" ]

  setData() {
    const fileInformation = this.fileTarget.files[0].name.split(".")[0].split("_")
    this.musicInstrumentTarget.value = fileInformation[0]
    this.codeTarget.value = fileInformation[1]
    this.musicKeyTarget.value = fileInformation[2].replace("♯", "#")
    this.bgmTarget.value = fileInformation[3]
    this.rhythmTarget.value = fileInformation[4]
    this.paternTarget.value = fileInformation[5].split(/(?=[A-Z])/).join('_').toLowerCase()
    this.panTarget.value = fileInformation[6]
    this.serialNumberTarget.value = fileInformation[7]
  }
}
