import ChannelService from "../src/channel_service"
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    pluginKey: String,
    appEnv: String,
    memberId: String,
    email: String,
  }

  connect() {
    this.boot();
  }

  disconnect() {
    this.shutdown();
  }

  memberIdValueChanged() {
    this.reboot();
  }

  boot() {
    const bootOptions = {
      pluginKey: this.pluginKeyValue,
      tags: [this.appEnvValue]
    }
    if (this.memberIdValue) {
      bootOptions.memberId = this.memberIdValue
      bootOptions.profile = {
        email: this.emailValue
      }
    }
    ChannelService.boot(bootOptions);
    ChannelService.addTags([this.appEnvValue]);
  }

  shutdown() {
    ChannelService.shutdown();
  }

  reboot() {
    this.shutdown();
    this.boot();
  }
}
