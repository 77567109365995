// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [ "id", "adminMoodSelect", "moodSelect", "moods", "uploadWrap", "videoPreview", "titleField" , "intentionIds", "minutes", "seconds", "climaxTime", "errorMessage", "clearButton", "loader" ]

  showVideo(event) {
    const videoFile = event.target.files[0];
    this.errorMessageTarget.classList.add('hidden')
    if (videoFile) {
      const splitNames = videoFile.name.split('.')
      const fileStem = splitNames.slice(0, splitNames.length - 1).join('')
      const fileExt = splitNames.slice(splitNames.length - 1).join('').toLowerCase()
      if (fileExt != 'mp4') {
        this.errorMessageTarget.innerHTML = '<p>現在サポートしている動画ファイルはMP4のみです。</p>'
        this.errorMessageTarget.classList.remove('hidden')
        return
      }
      const climaxTimeInput = this.climaxTimeTarget
      this.titleFieldTarget.value = fileStem
      var $source = $('.video-preview video');
      this.uploadWrapTarget.classList.add("hidden")
      this.videoPreviewTarget.classList.remove("hidden")
      this.errorMessageTarget.innerHTML = ''
      this.errorMessageTarget.classList.add('hidden')
      $source.attr("src", URL.createObjectURL(videoFile));
      $source[0].preload = 'metadata';
      $source[0].onloadedmetadata = () => {
        const duration = $source[0].duration
        const minute = parseInt(duration / 60)
        const second = parseInt(duration % 60)
        if (duration >= 240) {
          this.clearVideo()
          this.errorMessageTarget.innerHTML = '<p>4分以上のvideoには対応していません。</p>'
          this.errorMessageTarget.classList.remove('hidden')
          return
        }
        $('#song_request_minutes').val(minute)
        $('#song_request_seconds').val(second)
        $('#song_request_minutes')
          .closest('div')
          .find('.text')
          .html(parseInt(duration / 60))
        $('#song_request_seconds')
          .closest('div')
          .find('.text')
          .html(parseInt(duration % 60))
        $('#song_request_minutes')
          .parent()
          .find('div.text')
          .removeClass('default')
        $('#song_request_seconds')
          .parent()
          .find('div.text')
          .removeClass('default')
        $('#video-start-label').text('0:00')
        $('#video-end-label').text(minute + ':' + ('0'+second).slice(-2))
        if (climaxTimeInput.value) {
          const currentValue = $('#song_request_climax').val()
          const currentSeconds = parseInt(duration / 100 * currentValue)
          $('#slider-bubble').text(`${parseInt(currentSeconds / 60)}:${('0'+parseInt(currentSeconds % 60)).slice(-2)}`)
          climaxTimeInput.value = currentSeconds
        } else {
          $('#slider-bubble').text('')
          climaxTimeInput.value = Math.floor((parseInt(minute) * 60 + parseInt(second)) / 2)
        }
      }
      this.showRightPanel()
    }
  }

  clearVideo() {
    $('.video-area input').val('');
    this.uploadWrapTarget.classList.remove("hidden")
    this.videoPreviewTarget.classList.add("hidden")
  }

  toggleAnime() {
    $('#toggle-anime').toggle();
  }

  setMoodSelectOption(id) {
    const selectElement = this.moodSelectTarget
    selectElement.innerHTML = ''
    selectElement.parentElement.children[2].classList.add('default')
    if (id == '') {
      selectElement.parentElement.children[2].innerText = 'usageを選択してください'
    } else {
      const moodData = this.moodsTarget.dataset.moods
      const moods = JSON.parse(moodData)[id]
      const defaultOption = document.createElement('option')
      defaultOption.value = ''
      defaultOption.text = 'text'
      defaultOption.selected = true
      selectElement.appendChild(defaultOption)
      moods.sort((a, b) => a[0] - b[0]).forEach((mood) => {
        const option = document.createElement('option')
        option.value = mood[0]
        option.text = mood[1]
        selectElement.appendChild(option)
      })
      selectElement.parentElement.children[2].innerText = '選択してください'
    }
  }

  changeDeactive(elm) {
    elm.toggleClass('gradient-box')
    if (elm.hasClass('gradient-box')) {
      elm.removeClass('deactive')
      $(".usage-images .item:not('.gradient-box')").addClass('deactive')
    } else {
      elm.addClass('deactive')
    }
  }

  setDeactive() {
    const intentionId = this.intentionIdsTarget.value
    if (intentionId) {
      const target = document.getElementsByClassName(`item-${intentionId}`)
      const element = $(target).closest('div');
      this.changeDeactive(element)
      this.setMoodSelectOption(intentionId)
    }
  }

  clickUsage(event) {
    const element = $(event.target).closest('div');
    this.changeDeactive(element)

    const id = element.find('.intension-id')[0].innerHTML
    let ids = $('#song_request_intention_ids').val()

    if (ids) {
      const orgId = ids.split(',')[0]
      if (orgId == id) {
        ids = []
        this.setMoodSelectOption('')
      } else {
        const oldElm = $(`span[class="intension-id"]`)
          .filter((i, e) => $(e).text() == orgId)
          .first()
          .closest('div')
        this.changeDeactive(oldElm)
        ids = [id]
        this.setMoodSelectOption(id)
      }
    } else {
      ids = [id]
      this.setMoodSelectOption(id)
    }

    $('#song_request_intention_ids').val(ids)
  }

  clickClimax(event) {
    $('.climax-points .item').removeClass('active')
    $('.climax-points .item').addClass('deactive')
    const element = $(event.target).closest('div')
    element.removeClass('deactive')
    element.addClass('active')
    const value = $('.climax-points .item').index(element)

    $('#song_request_climax_point').val(['flat', 'round', 'up'][value])
  }

  toggle_loading() {
    const songRequestId = this.idTarget.value
    const url = `/api/v1/song_requests/${songRequestId}/status`

    $.ajax({
      url: url
    })
    .done((response) => {
      if(response.is_complete_analyze_movie && response.is_complete_create_song){
        this.display_keywords_and_songs();
        this.loaderTarget.classList.remove('active')
      } else {
        setTimeout(this.toggle_loading.bind(this), 5000)
      }
    })
    .fail((response) => {
      console.log("ERROR", response)
    })
  }

  display_keywords_and_songs(){
    const songRequestId = this.idTarget.value
    const url = `/api/v1/song_requests/${songRequestId}`

    $.ajax({
      url: url
    })
    .done((response)=> {
      if (response.keywords.length > 0) {
        $('#song-request-keywords').html('');
      }
      response.keywords.forEach((keyword) => {
        const span = `<span class='value border'>${keyword}</span>`
        $('#song-request-keywords').append(span)
      });

      const event = new CustomEvent("show_songs", {
        detail: {
          song_urls: response.song_urls,
          descriptions: response.descriptions
        }
      })
      window.dispatchEvent(event);
    })
    .fail((response) => {
      console.log("ERROR", response)
    })
  }

  showRangeValue(event) {
    const endPos = $('#video-end-label').text()
    if (endPos) {
      const currentValue = parseInt(event.target.value)
      const totalSeconds = parseInt(endPos.split(':')[0]) * 60 + parseInt(endPos.split(':')[1])
      const currentSeconds = parseInt(totalSeconds / 100 * currentValue)
      const minutes = parseInt(currentSeconds / 60)
      const seconds = parseInt(currentSeconds % 60)
      $('#slider-bubble').text(minutes + ':' + ('0'+seconds).slice(-2))
      const rangeWidth = $('#song_request_climax').width()
      const thumbHalfWidth = 25
      const left = ((currentValue/100) * (rangeWidth - thumbHalfWidth)) + thumbHalfWidth + 2
      const top = $('#song_request_climax').position().top - 15
      $('#slider-bubble').css({'position': 'absolute', 'left': left, 'top': top})
      this.climaxTimeTarget.value = currentSeconds
    }
  }

  setRangeValue() {
    const minutes = this.minutesTarget.value
    const seconds = this.secondsTarget.value
    const totalSeconds = parseInt(minutes) * 60 + parseInt(seconds)
    if (minutes && seconds) {
      $('#video-start-label').text('0:00')
      $('#video-end-label').text(minutes + ':' + ('0'+seconds).slice(-2))
      if (this.climaxTimeTarget.value) {
        const currentValue = $('#song_request_climax').val()
        const currentSeconds = parseInt(totalSeconds / 100 * currentValue)
        $('#slider-bubble').text(`${parseInt(currentSeconds / 60)}:${('0'+parseInt(currentSeconds % 60)).slice(-2)}`)
        this.climaxTimeTarget.value = currentSeconds
      } else {
        $('#slider-bubble').text('')
        this.climaxTimeTarget.value = Math.floor(totalSeconds / 2)
      }
    }
  }

  initialize() {
    this.element[this.identifier] = this
  }

  clickNext(event) {
    event.preventDefault()

    this.isStep2Complete = false
    this.songRequestId = -1
    this.errorMessageTarget.classList.add('hidden')

    var formData = new FormData($('form')[0])
    var fd1 = new FormData()
    var fd2 = new FormData()
    fd1.append('without_video', formData.get('song_request[video]').size == 0)
    for (var k of formData.keys()) {
      if (k == 'song_request[title]' || k == 'song_request[minutes]' || k == 'song_request[seconds]' || k == 'song_request[climax]' || k == 'song_request[climax_time]') {
        fd1.append(k, formData.get(k))
      }
      if (k == 'song_request[title]' || k == 'song_request[video]') {
        fd2.append(k, formData.get(k))
      }
    }

    if (!fd1.get('song_request[title]') || !fd1.get('song_request[minutes]') || !fd1.get('song_request[seconds]') || !fd1.get('song_request[climax]')) {
      this.errorMessageTarget.innerHTML = '<p>project / time / chorus を入力してください。</p>'
      this.errorMessageTarget.classList.remove('hidden')
      return
    }

    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    fetch('/song_requests/step1', {
      method: 'POST',
      body: fd1,
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': token,
      },
    })
    .then(response => {
      if (!response.ok) {
        if (response.status == 409) {
          this.errorMessageTarget.innerHTML = '<p>作曲リクエストは同時に1個までしか作成できません。作曲が終わるまでお待ちください。</p>'
        }
        else if (response.status == 426) {
          this.errorMessageTarget.innerHTML = '<p>現在のプランの制限によりご利用になれません。</p>'
        }
        this.errorMessageTarget.classList.remove('hidden')
        throw new Error(`${response.statusText}(${response.status})`)
      }
      return response.json()
    })
    .then(data => {
      this.songRequestId = data['id']
      this.abortStep2Controller = new AbortController()
      const signal = this.abortStep2Controller.signal
      $('#optionalQuestion').modal('setting', 'closable', false).modal('show')
      fetch(`/song_requests/${this.songRequestId}/step2`, {
        method: 'PUT',
        body: fd2,
        credentials: 'same-origin',
        headers: {
          'X-CSRF-Token': token,
        },
        signal: signal,
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`${response.statusText}(${response.status})`)
        }
        return response.json()
      })
      .then(data => {
        this.isStep2Complete = true
        if (this.application.getControllerForElementAndIdentifier(document.getElementById('optionalQuestion'), 'modal').isStep3Complete) {
          location.href = `/song_requests/${this.songRequestId}`
        }
      })
      .catch(err => {
        console.error(`1: ${err}`)
      })
    })
    .catch(err => {
      console.error(`2: ${err}`)
    })
  }

  showClearButton(event) {
    this.clearButtonTarget.classList.remove('transparent')
  }

  hideClearButton(event) {
    this.clearButtonTarget.classList.add('transparent')
  }

  hideLoader(event) {
    event.currentTarget.classList.remove('active')
  }

  limitMessage(event) {
    event.preventDefault()
    $('#downloadLimitMessage').modal('show')
  }
  
  clickWithoutVideoButton(event) {
    event.preventDefault()
    this.showRightPanel()
  }

  showRightPanel() {
    $('#song-request-left').addClass('request_show')// .css('width', '50%')
    $('#song-request-right').addClass('request_show')// .css('display', 'block')
    $('#without-video-area').css('display', 'none')
  }
}
