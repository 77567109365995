// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "id", "kind", "ids", "urls", "descriptions", "songList", "downloadable" ]
  wavesurfers = [];
  connect(){
    if(this.urlsTarget.value == '') {
      return
    }

    const descriptions = JSON.parse(this.descriptionsTarget.value)
    this.show_songs(this.urlsTarget.value.split(' '), descriptions)
  }

  on_fetch_song_urls(event){
    const descriptions = event.detail.descriptions
    this.show_songs(event.detail.song_urls, descriptions)
  }

  show_songs(song_urls, descriptions){
    const urls = song_urls
    const that = this
    const downloadable = this.downloadableTarget.value == 'true'

    $('.songs-list .song-item').each(function(i, obj) {
      const id = $(obj).attr('id')
      let songRequestId
      if (that.kindTarget.value == 1) {
        songRequestId = that.idTarget.value
      } else {
        songRequestId = that.idsTarget.value.split(' ')[i]
      }
      const downloadLink = `/song_requests/${songRequestId}/download_bgm?url=${urls[i]}&kind=${that.kindTarget.value}`

      that.convert_wave(id, urls[i], downloadable ? downloadLink : null)
      const explanation = document.createElement('div')
      explanation.classList.add('explanation')
      if (descriptions && descriptions[i]) {
        const desc = document.createTextNode(descriptions[i])
        explanation.appendChild(desc)
        const parent = obj.getElementsByClassName('sound-item')[0]
        parent.appendChild(explanation)
      }
    })
    $('.songs-list').show()
    $('.video-labels').show()
    $('.creating-message').hide()
  }

  sec_to_string(seconds) {
    var sec_num = parseInt(seconds);
    var minutes = Math.floor(sec_num / 60);
    var seconds = sec_num % 60;

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }

  play_song(event) {
    const index = $(event.target).closest('.song-item').index()
    const video = $(`#song-request-show-video`)[0]

    this.wavesurfers.map((wavesurfer, i) => {
      if (i == index) {
        const currentTimeSec = wavesurfer.getCurrentTime()
        if (currentTimeSec === 0 && video) {
          video.currentTime = 0
        }
        this.wavesurfers[i].playPause();
      } else {
        this.wavesurfers[i].pause();
      }
    })
  }

  backward(event) {
    const songItem = $(event.target).closest('.song-item')
    this.wavesurfers.map((wavesurfer, i) => {
      if (i == songItem.index()) {
        const video = this.kindTarget.value == 1 ? $(`#song-request-show-video`)[0] : $(`#${songItem.attr('id')} video`).get(0)
        wavesurfer.seekTo(0)
        video.currentTime = 0
      }
    })
  }

  play_loop(event) {
    $(event.target).toggleClass('active')
  }

  convert_wave(id, url, downloadLink) {
    var wavesurfer = WaveSurfer.create({
      barWidth: 2,
      barHeight: 1,
      barGap: null,
      partialRender: true,
      container: `#waveform-${id}`,
      waveColor: "#00bff3C0",
      progressColor: "#0097c0c0",
      height: 56,
      barRadius: 3,
      normalize: 0,
    });
    wavesurfer.load(url)

    const that = this
    wavesurfer.on('ready', function () {
      $(`#${id} .total-time`).text(that.sec_to_string(wavesurfer.getDuration()))
      that.check_all_ready()
    });
    wavesurfer.on('play', function () {
      $(`#${id} .play.circle.icon`).attr('class', 'pause circle icon');
      if (that.kindTarget.value == 2) {
        $(`#${id} video`).get(0).play()
      } else {
        $('video.video-tag').get(0).play()
      }
      const tagName = that.kindTarget.value == 2 ? `#${id} video` : 'video.video-tag'
      if ($(tagName).get(0).paused) {
        $(tagName).get(0).play()
      }
    });
    wavesurfer.on('pause', function () {
      $(`#${id} .song .pause.circle.icon`).attr('class', 'play circle icon');

      const tagName = that.kindTarget.value == 2 ? `#${id} video` : 'video.video-tag'
      $(tagName).get(0).pause()
    });
    wavesurfer.on('audioprocess', function () {
      const currentTime = wavesurfer.getCurrentTime()
      $(`#${id} .current-time`).text(that.sec_to_string(currentTime))
    });
    wavesurfer.on('finish', function () {
      if ($(`#${id} .sync.icon`).hasClass('active')) {
        setTimeout(() => wavesurfer.play(), 100)
      } else {
        if (that.kindTarget.value == 2) {
          $(`#${id} video`).get(0).pause()
        } else {
          $('video.video-tag').get(0).pause()
        }
      }
    });

    if (downloadLink) {
      $(`#${id} .download-content`).attr("href", downloadLink)
    }
    $(`#${id} .ui.range`).range({
      min: 0,
      max: 10,
      start: 10,
      onChange: function(val) {
        wavesurfer.setVolume(val / 10)
      }
    });

    this.wavesurfers.push(wavesurfer)
  }

  check_all_ready() {
    if (this.wavesurfers.some((wavesurfer) => !wavesurfer.isReady)) return

    const target = this.songListTarget
    target.classList.remove('segment')
    target.querySelector('.ui.active.dimmer').classList.remove('active')
  }

  limitMessage(event) {
    if ($(event.srcElement).closest('a').attr('href') === undefined) {
      $('#downloadLimitMessage').modal('show')
    }
  }
}
