// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import Rails from "@rails/ujs"
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "cardholder" ]
  static values = { publishableKey: String, clientSecret: String }

  connect() {
    this.stripe = Stripe(this.publishableKeyValue)
    const elements = this.stripe.elements();

    this.cardElement = elements.create('card', {
      hidePostalCode: true,
    });
    this.cardElement.mount('#card-element')
  }

  async confirm(e) {
    e.preventDefault()
    Rails.disableElement(this.element)
    const { setupIntent } = await this.stripe.confirmCardSetup(
        this.clientSecretValue,
        {
          payment_method: {
            card: this.cardElement,
            billing_details: {
              name: this.cardholderTarget.value,
            },
          },
        }
    );

    if (setupIntent.status === 'succeeded') {
      this.element.submit()
    } else {
      Rails.enableElement(this.element)
    }
  }
}
