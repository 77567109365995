// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// デフォルトのハンドラをメモ化
const defaultErrorHandler = application.handleError.bind(application);

// Sentryをエラーログに設定してデフォルトハンドラをprependする
const sentryErrorHandler = (error, message, detail = {}) => {
  defaultErrorHandler(error, message, detail);
  Sentry.captureException(error, { message, ...detail });
};

// 新たに組み立てたハンドラでデフォルトハンドラをオーバーライド
application.handleError = sentryErrorHandler;
