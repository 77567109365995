require("semantic-ui-sass")

$(document).on('turbolinks:load', () => {
	$('.ui.dropdown')
		.dropdown()
	;
	
	$('.tabular.menu .item')
		.tab()
	;

  $('.ui.checkbox')
    .checkbox()
  ;
});
