import ApplicationController from "./application_controller";

export default class extends ApplicationController {
static targets = [ "moodSelect", "moods", "intentionIds", "moodInvalidFeedback", "usageInvalidFeedback", "waitStep2"]


    setModalMoodSelectOption(id) {
        const selectElement = this.moodSelectTarget
        selectElement.innerHTML = ''
        selectElement.parentElement.children[2].classList.add('default')
        if (id == '') {
          selectElement.parentElement.children[2].innerText = 'usageを選択してください'
        } else {
          const moodData = this.moodsTarget.dataset.moods
          const moods = JSON.parse(moodData)[id]
          const defaultOption = document.createElement('option')
          defaultOption.value = ''
          defaultOption.text = 'text'
          defaultOption.selected = true
          selectElement.appendChild(defaultOption)
          moods.sort((a, b) => a[0] - b[0]).forEach((mood) => {
            const option = document.createElement('option')
            option.value = mood[0]
            option.text = mood[1]
            selectElement.appendChild(option)
          })
          selectElement.parentElement.children[2].innerText = '選択してください'
        }
    }

    clickModalUsage(event) {
        const element = $(event.target).closest('div');
        this.changeDeactive(element)

        const id = element.find('.intension-id')[0].innerHTML
        let ids = $('#song_request_intention_ids').val()

        if (ids) {
          const orgId = ids.split(',')[0]
          if (orgId == id) {
            ids = []
            this.setMoodSelectOption('')
          } else {
            const oldElm = $(`span[class="intension-id"]`)
              .filter((i, e) => $(e).text() == orgId)
              .first()
              .closest('div')
            this.changeDeactive(oldElm)
            ids = [id]
            this.setMoodSelectOption(id)
          }
        } else {
          ids = [id]
          this.setMoodSelectOption(id)
        }

        $('#song_request_intention_ids').val(ids)
    }

    changeDeactive(elm) {
        elm.toggleClass('gradient-box')
        if (elm.hasClass('gradient-box')) {
            elm.removeClass('deactive')
            $(".usage-images .item:not('.gradient-box')").addClass('deactive')
        } else {
            elm.addClass('deactive')
        }
    }

    setMoodSelectOption(id) {
        const selectElement = this.moodSelectTarget
        selectElement.innerHTML = ''
        selectElement.parentElement.children[2].classList.add('default')
        if (id == '') {
            selectElement.parentElement.children[2].innerText = 'usageを選択してください'
        } else {
            const moodData = this.moodsTarget.dataset.moods
            const moods = JSON.parse(moodData)[id]
            const defaultOption = document.createElement('option')
            defaultOption.value = ''
            defaultOption.text = 'text'
            defaultOption.selected = true
            selectElement.appendChild(defaultOption)
            moods.sort((a, b) => a[0] - b[0]).forEach((mood) => {
                const option = document.createElement('option')
                option.value = mood[0]
                option.text = mood[1]
                selectElement.appendChild(option)
            })
            selectElement.parentElement.children[2].innerText = '選択してください'
        }
    }

    initialize() {
        this.element[this.identifier] = this
    }

    clickCreateMusic(event) {
        event.preventDefault()

        this.isStep3Complete = false

        var songRequestController = this.application.getControllerForElementAndIdentifier(document.getElementById('songRequest'), 'song-request')
        const id = songRequestController.songRequestId
        const intention = this.intentionIdsTarget.value
        const mood = this.moodSelectTarget.value

        if (intention == "") {
            this.usageInvalidFeedbackTarget.classList.add('show')
        } else {
            this.usageInvalidFeedbackTarget.classList.remove('show')
        }

        if (mood == "") {
            this.moodInvalidFeedbackTarget.classList.add('show')
        } else {
            this.moodInvalidFeedbackTarget.classList.remove('show')
        }

        if (intention != "" && mood != "") {
            var fd = new FormData()
            fd.append('song_request[intention_ids][]', intention)
            fd.append('song_request[mood_ids][]', mood)

            const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            fetch(`/song_requests/${id}/step3`, {
                method: 'PUT',
                body: fd,
                credentials: 'same-origin',
                headers: {
                  'X-CSRF-Token': token,
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`${response.statusText}(${response.status})`)
                }
                return response.json()
            })
            .then(data => {
                this.isStep3Complete = true
                $(this.element).modal('hide')
                if (songRequestController.isStep2Complete) {
                    location.href = '/song_requests/' + id;
                } else {
                    $(this.waitStep2Target).modal('setting', 'closable', false).modal('show')
                }
            })
            .catch(console.error)
        }
    }

    clickClose(event) {
        event.preventDefault()

        var songRequestController = this.application.getControllerForElementAndIdentifier(document.getElementById('songRequest'), 'song-request')
        const id = songRequestController.songRequestId
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        songRequestController.abortStep2Controller.abort()

        fetch(`/song_requests/${id}`, {
            method: 'DELETE',
            body: {},
            credentials: 'same-origin',
            headers: {
                'X-CSRF-Token': token,
                'Accept': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`${response.statusText}(${response.status})`)
            }
            return response.json()
        })
        .then(data => {
            $(this.element).modal('hide')
        })
        .catch(console.error)
    }
}
